<template>
  <b-modal
      v-model="showUser"
      centered
      title="被审核单位增加"
      size="lg"
      title-class="font-18"
      hide-footer
  >
    <addReportProfessionalForm v-model="form"></addReportProfessionalForm>
    <div class="mt-3 text-center">
      <button type="button" class="btn btn-info h30 w-md mr-3" @click="addUser">
        确定添加
      </button>
      <button
          type="button"
          class="btn btn-secondary h30 w-md"
          @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import {addProfessional} from "@/api/admin/exam/checkPower";
import addReportProfessionalForm from "@/components/enroll/add-report-professional-form.vue";
export default {
  data() {
    return {
      form: {},
      showUser: false
    };
  },
  methods: {
    show(form){
      this.form = form
      this.form["uid"] = this.$route.query['uid']
      this.showUser = true
    },
    hide(){
      this.showUser = false
    },
    addUser() {
      addProfessional(this.form).then(res=>{
        if (res.status){
          this.showUser = false;
          this.$notify.success("新增成功")
          this.$emit("success")
        }
      })
    },
  },
  components: {
    addReportProfessionalForm
  },
  mounted() {
  }
};
</script>

<style></style>
