<template>
  <div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">职位号</label>
      <div class="col-sm-10 p-0 flexList">
        <input type="text" name="" value=""  maxlength="20"  v-model="form.zydm"
               class="form-control w-100 h30 " />
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">职位名称</label>
      <div class="col-sm-10 p-0 flexList">
        <input type="text" name="" value=""  maxlength="50"  v-model="form.zymc"
               class="form-control w-100 h30 " />
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">缴费金额</label>
      <div class="col-sm-10 p-0 flexList">
        <input type="text" name="" value=""  maxlength="10"  v-model="form.jfje"
               class="form-control w-100 h30 " />
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">报考比例</label>
      <div class="col-sm-10 p-0 flexList">
        <input type="text" name="" value=""  maxlength="10"  v-model="form.bkbl"
               class="form-control w-100 h30 " />
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">名额</label>
      <div class="col-sm-10 p-0 flexList">
        <input type="text" name="" value=""  maxlength="10"  v-model="form.me"
               class="form-control w-100 h30 " />
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">市州代码</label>
      <div class="col-sm-10 p-0 flexList">
        <input type="text" name="" value=""  maxlength="50"  v-model="form.szdm"
               class="form-control w-100 h30 " />
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">市州名称</label>
      <div class="col-sm-10 p-0 flexList">
        <input type="text" name="" value=""  maxlength="50"  v-model="form.sz"
               class="form-control w-100 h30 " />
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">范围对象</label>
      <div class="col-sm-10 p-0 flexList">
        <input type="text" name="" value=""  maxlength="50"  v-model="form.fwdx"
               class="form-control w-100 h30 " />
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">学历</label>
      <div class="col-sm-10 p-0 flexList">
        <input type="text" name="" value=""  maxlength="50"  v-model="form.xl"
               class="form-control w-100 h30 " />
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">学位</label>
      <div class="col-sm-10 p-0 flexList">
        <input type="text" name="" value=""  maxlength="50"  v-model="form.xw"
               class="form-control w-100 h30 " />
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">专业</label>
      <div class="col-sm-10 p-0 flexList">
        <input type="text" name="" value=""  maxlength="50"  v-model="form.zy"
               class="form-control w-100 h30 " />
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">加试</label>
      <div class="col-sm-10 p-0 flexList">
        <input type="text" name="" value=""  maxlength="50"  v-model="form.js"
               class="form-control w-100 h30 " />
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">其他条件</label>
      <div class="col-sm-10 p-0 flexList">
        <input type="text" name="" value=""  maxlength="50"  v-model="form.qttl"
               class="form-control w-100 h30 " />
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">备注</label>
      <div class="col-sm-10 p-0 flexList">
        <input type="text" name="" value=""  maxlength="50"  v-model="form.bz"
               class="form-control w-100 h30 " />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "add-report-professional-form",
  model: {
    prop: "form",
    event: "input",
  },
  props:{
    form: Object,
  },
  form: {
    deep: true,
    handler(form) {
      this.$emit("input", form);
    },
  },
}
</script>

<style scoped>

</style>